.book-list {
  width: 1200px;
  margin: auto;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}

.book {
  width: 260px;
  margin: 40px 20px 20px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #FFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  transition: box-shadow .3s;
}

.book:hover {
  box-shadow: 0 2px 25px rgba(0, 0, 0, .2);
}

.book-head {
  position: relative;

  padding: 25px 35px;
  overflow: hidden;
  cursor: pointer;
}

.cover {
  position: relative;

  width: 190px;
  height: 110px;
}

.cover-border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  width: 150px;
  height: 70px;
  border: 2px solid #000;
  outline: 4px solid #000;
  outline-offset: 6px;
}

.book-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: inherit;
  height: inherit;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.book-body {
  padding: 10px;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 98;
  transition: all .3s;
}

.mask-content {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 15px;
  opacity: 0;
  z-index: 99;
}

.mask-content:hover {
  opacity: 1;
}

.mask-content:hover + .mask {
  visibility: visible;
  opacity: .6;
  background-color: #1a1a1a;
}

.mask-top {
  display: flex;
  flex-direction: row-reverse;
}

.mask-bottom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
