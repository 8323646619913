.toolbar {
  width: 300px;
  height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  font-size: 0; /* hack: 去除inline-block间的空格 */
  background: #ECE9E6;
  background: -webkit-linear-gradient(to bottom, #FFFFFF, #ECE9E6);
  background: linear-gradient(to bottom, #FFFFFF, #ECE9E6);
  box-shadow: 0 3px 15px 1px rgba(0, 0, 0, .2);
  transition: opacity .5s, transform .5s;
}

.toolbar-btn {
  border: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  transition: .3s;

  display: inline-block;
  height: inherit;
  width: 40px;
  padding-left: 8px;
  padding-right: 8px;
}

.toolbar-btn:not(.drag-me):hover {
  background-color: rgba(0, 0, 0, .15);
}

.drag-me {
  cursor: url("../assets/move.png"), auto;
}
