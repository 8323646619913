.chapter-list li {
  list-style: none;
}

.chapter-list a {
  text-decoration: none;
  color: inherit;
}

.chapter-list {
  padding-left: 10%;
  overflow: hidden;
  transition: .3s;
}

.chapter-list-item {
  min-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  position: relative;
}

.chapter-list-item:not(:last-child):before {
  content: '';
  display: block;
  position: absolute;
  left: 8px;
  height: 100%;
  width: 2px;
  background-color: #726E6B;
}

.item-content {
  display: flex;
}

.item-mark {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  margin-right: 1em;
  background: #e0e0e0;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  flex-shrink: 0; /* 空间不足时不缩小 */
  transition: .3s;
}

.item-mark:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: -100%;
  width: 100%;
  height: 2px;
  background-color: #726E6B;
}

.item-mark:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 17px;
  height: 17px;
  transition: transform .3s;
}

.item-mark:hover {
  border-color: #F5C28A;
}

.chapter-url {
  font-size: .875rem;
  transition: none;
}

.chapter-url:active {
  color: #f58f48;
}
